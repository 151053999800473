import { YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IGetVehicleRequest, RenaveStatusEnum } from "../../../../models";

export const MyStockDataFormValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const schema = Yup.object<IGetVehicleRequest>({
    search: Yup.string().notRequired(),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
    step: Yup.number<RenaveStatusEnum>().notRequired(),
    startDate: Yup.string()
      .notRequired()
      .test(
        "invalid",
        t("Invalid Date"),
        (value: string | null | undefined): boolean => {
          if (!value) return true;
          const parsedDate = new Date(value);
          return !isNaN(parsedDate.getTime());
        }
      ),
    finishDate: Yup.string()
      .notRequired()
      .test(
        "invalid",
        t("Invalid Date"),
        (value: string | null | undefined): boolean => {
          if (!value) return true;
          const parsedDate = new Date(value);
          return !isNaN(parsedDate.getTime());
        }
      ),
  });

  return schema;
};
