import {
  Combo,
  FormikControlAtom,
  MaskHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import {
  useRenave,
  useRenaveContext,
  useSessionContext,
  useZipcodeAddress,
} from "../../../hooks";
import {
  RenaveMenuStepsEnum,
  ISaveOutOfStockPersonRequest,
  SaveOutOfStockPersonRequest,
  TypeDocumentEnum,
  CheckoutTypeEnum,
} from "../../../models";
import { CheckOutPersonFormMoleculeValidation } from "./check-out-person-form.molecule.validation";

interface IProps {
  setIsSubmitting: (v: boolean) => void;
  goSubmit: boolean;
  creditsToSubmit: () => Promise<boolean>;
  viewMode?: boolean;
}

export const CheckOutPersonFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { setVehicleCheckoutContext, vehicleCheckoutContext } =
    useRenaveContext();
  const { getAddressByZipcode } = useZipcodeAddress();

  const { saveOutOfStock } = useRenave();
  const {
    showGenericSuccessModal,
    handleFormikException,
    showGenericErrorModal,
  } = useSessionContext();

  const { id } = useParams<{ id: string }>();
  const { setMenuCurrentStepContext } = useRenaveContext();
  const history = useHistory();

  const formik = useFormik<ISaveOutOfStockPersonRequest>({
    initialValues: {
      id: vehicleCheckoutContext?.id,
      buyerDocument: vehicleCheckoutContext?.buyerDocument,
      buyerEmail: vehicleCheckoutContext?.buyerEmail,
      buyerName: vehicleCheckoutContext?.buyerName,
      city: vehicleCheckoutContext?.city,
      docType: vehicleCheckoutContext?.docType?.toString(),
      exitDate: vehicleCheckoutContext?.exitDate,
      neighborhood: vehicleCheckoutContext?.neighborhood,
      number: vehicleCheckoutContext?.number,
      saleValue: vehicleCheckoutContext?.saleValue || null,
      state: vehicleCheckoutContext?.state,
      street: vehicleCheckoutContext?.street,
      zipCode: vehicleCheckoutContext?.zipCode,
      complement: vehicleCheckoutContext?.complement,
    } as ISaveOutOfStockPersonRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        const credits = await props.creditsToSubmit();
        if (credits) {
          var request = new SaveOutOfStockPersonRequest(values);
          await saveOutOfStock(request);
          showGenericSuccessModal(null, () => {
            history.push(paths.checkOutDanfe(id));
            setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckOutDanfe);
          });
        }
      } catch (error: any) {
        handleFormikException(
          error?.message,
          setErrors,
          t("ErrorMsgOutOfStock")
        );
      } finally {
        setVehicleCheckoutContext({
          ...values,
          docType:
            values.docType === "0"
              ? TypeDocumentEnum.Cpf
              : TypeDocumentEnum.Cnpj,
          checkOutType: CheckoutTypeEnum.Exit,
        });
      }
    },
    validationSchema: CheckOutPersonFormMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setValues({ ...formik.values, id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const dataComboType = useMemo(
    () => [
      new Combo({
        title: t("PersonalNumber"),
        value: TypeDocumentEnum.Cpf.toString(),
      }),
      new Combo({
        title: t("BusinessNumber"),
        value: TypeDocumentEnum.Cnpj.toString(),
      }),
    ],
    [t]
  );

  //#region ZipCode
  const [zipCodeAddress, setZipCodeAddress] = useState<ZipcodeAddress>();

  const handleGetAddress = useCallback(
    async (cep: string) => {
      try {
        const address = await getAddressByZipcode(cep);
        setZipCodeAddress(address);
        if (!address?.erro && address?.uf) {
          formik.setFieldValue("city", address.localidade);
          formik.setFieldValue("state", address.uf);
          formik.setFieldValue("street", address.logradouro);
          formik.setFieldValue("neighborhood", address.bairro);
          formik.setFieldValue("buyerCityCode", address.ibge);
        } else {
          throw new Error("Address not found");
        }
      } catch (e) {
        setZipCodeAddress(undefined);
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("street", "");
        formik.setFieldValue("neighborhood", "");
        formik.setFieldValue("buyerCityCode", "");
        showGenericErrorModal(t("ErrorMsgZipCodeNotFound"));
      }
    },
    [getAddressByZipcode, showGenericErrorModal, formik, t]
  );
  useEffect(() => {
    if (formik?.values.zipCode?.replace(/[^\d]/g, "").length === 8) {
      setZipCodeAddress(undefined);
      handleGetAddress(formik.values.zipCode);
    }
  }, [formik.values.zipCode]); //eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  useEffect(() => {
    formik.setFieldValue("BuyerDocument", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.docType]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          type="select"
          translate={t}
          formik={formik}
          property={"docType"}
          label={t("DocumentType")}
          data={dataComboType}
          md={3}
          disabled={props.viewMode}
        />
        {formik.values.docType === TypeDocumentEnum.Cpf.toString() && (
          <FormikControlAtom
            translate={t}
            mask={MaskHelper.Cpf}
            formik={formik}
            property={"buyerDocument"}
            label={t("BuyerPersonaNumber")}
            md={4}
            disabled={props.viewMode}
          />
        )}
        {formik.values.docType === TypeDocumentEnum.Cnpj.toString() && (
          <FormikControlAtom
            mask={MaskHelper.Cnpj}
            translate={t}
            formik={formik}
            property={"buyerDocument"}
            label={t("BuyerBusinessNumber")}
            md={4}
            disabled={props.viewMode}
          />
        )}
        {!formik.values.docType && <Col></Col>}
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          maxLength={100}
          property={"buyerName"}
          label={t("BuyerName")}
          md={6}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          type={"money"}
          formik={formik}
          label={t("SaleValue")}
          property="saleValue"
          translate={t}
          placeholder="R$"
          md={4}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerEmail"}
          label={t("BuyerEmail")}
          md={6}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          mask={MaskHelper.Date}
          property={"exitDate"}
          label={t("ExitDate")}
          md={3}
          disabled={props.viewMode}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          mask={MaskHelper.Cep}
          formik={formik}
          property={"zipCode"}
          label={t("ZipCode")}
          md={3}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"city"}
          maxLength={100}
          label={t("City")}
          disabled={!!zipCodeAddress?.localidade || props.viewMode}
          md={6}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"state"}
          maxLength={2}
          label={t("State")}
          md={1}
          placeholder={t("UF")}
          disabled={!!zipCodeAddress?.uf || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"street"}
          maxLength={200}
          label={t("Street")}
          md={6}
          disabled={!!zipCodeAddress?.logradouro || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"number"}
          maxLength={6}
          label={t("Number")}
          md={2}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"neighborhood"}
          maxLength={100}
          label={t("Neighborhood")}
          md={5}
          disabled={!!zipCodeAddress?.bairro || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"complement"}
          maxLength={100}
          label={t("Complement")}
          md={5}
          disabled={props.viewMode}
        />
      </Form.Row>
      <button type="submit" className="hide"></button>
    </Form>
  );
};
