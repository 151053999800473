import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ModalBodyFormRow = styled(Form.Row)`
  width: 360px;
  margin: 10px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .col {
    padding: 0;
  }
  label {
    font-weight: 700;
  }
`;

export const ActionButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
