import React from "react";
import { CustomLabelWrapper } from "./icon-custom-label.atom.style";
import { CoxIcon, Tooltip } from "c4u-web-components";

interface IIConCustomLabelProps {
  label: string,
  tooltipContent: string
}

export const IconCustomLabel: React.FC<IIConCustomLabelProps> = ({ label, tooltipContent }) => {

  return (
      <CustomLabelWrapper>
        <span>{label}</span>
        <Tooltip
          content={tooltipContent}
          width="275px"
          placement="top"
        >
          <CoxIcon name={"info"} width={18} height={18} fillColor="#ffd478"/>
        </Tooltip>
      </CustomLabelWrapper>
  );
};
