import {
  FormikControlAtom,
  MaskHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { EnumHelper } from "../../../helper";
import {
  useRenaveContext,
  useSessionContext,
  useZipcodeAddress,
} from "../../../hooks";
import {
  IPostCustomerCheckOutZeroKmRequest,
  PostCustomerCheckOutZeroKmRequest,
  RenaveMenuStepsZeroKmEnum,
  TypeDocumentEnum,
} from "../../../models";
import { FormikControlCalendarAtom } from "c4u-web-components";
import { ZeroKmPersonCheckoutFormValidation } from "./check-out-customer-form-zero-km.molecule.validation";

interface IProps {
  setIsSubmitting: (v: boolean) => void;
  goSubmit?: boolean;
  goClearForm?: boolean;
  creditsToSubmit: () => Promise<boolean>;
  saveCheckout: (v: PostCustomerCheckOutZeroKmRequest) => Promise<void>;
  viewMode?: boolean;
}

export const CheckOutCustomerFormZeroKmMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    showGenericSuccessModal,
    handleFormikException,
    showGenericErrorModal,
  } = useSessionContext();
  const {
    zeroKmVehicleEntryContext,
    zeroKmVehicleCheckoutContext,
    setZeroKmVehicleCheckoutContext,
    setMenuCurrentStepZeroKmContext,
    userContext,
  } = useRenaveContext();

  const { getAddressByZipcode } = useZipcodeAddress();

  const { id } = useParams<{ id: string }>();

  const formik = useFormik<IPostCustomerCheckOutZeroKmRequest>({
    initialValues: {
      ...zeroKmVehicleCheckoutContext,
      id: parseInt(id),
    } as IPostCustomerCheckOutZeroKmRequest,
    onSubmit: async (values, { setErrors }) => {
      const request = new PostCustomerCheckOutZeroKmRequest(values);
      try {
        const credits = await props.creditsToSubmit();
        if (credits) {
          await props.saveCheckout(request);
          showGenericSuccessModal(null, () => {
            history.push(paths.vehicleCheckedOutZeroKm(id));
            setMenuCurrentStepZeroKmContext(
              RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm
            );
          });
        }
      } catch (error: any) {
        handleFormikException(
          error?.message,
          setErrors,
          t("ErrorMsgOutOfStock")
        );
      } finally {
        setZeroKmVehicleCheckoutContext({
          ...values,
          buyerDocumentType: request.buyerDocumentType ?? undefined,
          checkOutZeroKmType: request.checkOutZeroKmType ?? undefined,
        });
      }
    },
    validationSchema: ZeroKmPersonCheckoutFormValidation(
      zeroKmVehicleEntryContext?.invoiceKey ?? ""
    ),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setFieldValue("id", parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [zipCodeAddress, setZipCodeAddress] = useState<ZipcodeAddress>();
  const handleGetAddress = useCallback(
    async (cep: string) => {
      try {
        const address = await getAddressByZipcode(cep);
        setZipCodeAddress(address);
        if (!address?.erro && address?.uf) {
          formik.setFieldValue("buyerStreet", address.logradouro);
          formik.setFieldValue("buyerCityCode", address.ibge);
          formik.setFieldValue("buyerNeighborhood", address.bairro);
          formik.setFieldValue("buyerCity", address.localidade);
          formik.setFieldValue("buyerUF", address.uf);
        } else {
          throw new Error("Address not found");
        }
      } catch (e) {
        setZipCodeAddress(undefined);
        formik.setFieldValue("buyerStreet", "");
        formik.setFieldValue("buyerCityCode", "");
        formik.setFieldValue("buyerNeighborhood", "");
        formik.setFieldValue("buyerCity", "");
        formik.setFieldValue("buyerUF", "");
        showGenericErrorModal(t("ErrorMsgZipCodeNotFound"));
      }
    },
    [getAddressByZipcode, showGenericErrorModal, formik, t]
  );
  useEffect(() => {
    if (formik?.values.buyerZipCode?.replace(/[^\d]/g, "").length === 8) {
      setZipCodeAddress(undefined);
      handleGetAddress(formik.values.buyerZipCode);
    }
  }, [formik.values.buyerZipCode]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.viewMode) formik.setFieldValue("buyerDocument", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.buyerDocumentType]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.goClearForm) {
      setZipCodeAddress(undefined);
      formik.resetForm({
        values: {
          id: parseInt(id),
          saleValue: undefined,
          buyerDocumentType: undefined,
          buyerDocument: "",
          buyerName: "",
          buyerEmail: "",
          buyerZipCode: "",
          buyerStreet: "",
          buyerNumber: "",
          buyerComplement: "",
          buyerNeighborhood: "",
          buyerCityCode: "",
          buyerCity: "",
          buyerUF: "",
          stockExitDate: undefined,
          checkOutInvoiceKey: "",
          dealershipEmail:
            userContext?.dealership?.email || formik.values.dealershipEmail,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goClearForm]);

  useEffect(() => {
    props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (userContext?.dealership.email && !formik.values.dealershipEmail)
      formik.setFieldValue("dealershipEmail", userContext.dealership.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.dealership.email, formik.values.dealershipEmail]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          type="select"
          translate={t}
          formik={formik}
          property={"buyerDocumentType"}
          label={t("DocumentType")}
          data={EnumHelper.getComboFromEnum(TypeDocumentEnum, t)}
          func={(v?: string) => (!!v?.toString() ? v : "")}
          xs={4}
          lg={3}
          disabled={props.viewMode}
        />
        {formik.values.buyerDocumentType?.toString() ===
          TypeDocumentEnum.Cpf.toString() && (
          <FormikControlAtom
            translate={t}
            mask={MaskHelper.Cpf}
            formik={formik}
            property={"buyerDocument"}
            label={t("BuyerPersonaNumber")}
            xs={8}
            sm={6}
            lg={4}
            disabled={props.viewMode}
          />
        )}
        {formik.values.buyerDocumentType?.toString() ===
          TypeDocumentEnum.Cnpj.toString() && (
          <FormikControlAtom
            mask={MaskHelper.Cnpj}
            translate={t}
            formik={formik}
            property={"buyerDocument"}
            label={t("BuyerBusinessNumber")}
            xs={8}
            sm={6}
            lg={4}
            disabled={props.viewMode}
          />
        )}
        {!formik.values.buyerDocumentType && <Col></Col>}
      </Form.Row>

      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          maxLength={100}
          property={"buyerName"}
          label={t("BuyerName")}
          sm={10}
          lg={8}
          disabled={props.viewMode}
        />
      </Form.Row>

      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerEmail"}
          label={t("BuyerEmail")}
          sm={6}
          lg={5}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"dealershipEmail"}
          label={t("DealershipEmail")}
          placeholder={t("Loading") + "..."}
          sm={6}
          lg={5}
          disabled
        />
      </Form.Row>

      <Form.Row>
        <FormikControlAtom
          translate={t}
          mask={MaskHelper.Cep}
          formik={formik}
          property={"buyerZipCode"}
          label={t("ZipCode")}
          xs={5}
          md={4}
          lg={3}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerCity"}
          maxLength={100}
          label={t("City")}
          disabled={!!zipCodeAddress?.localidade || props.viewMode}
          xs={7}
          md={6}
          lg={5}
          xl={6}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerUF"}
          label={t("State")}
          maxLength={2}
          xs={2}
          xl={1}
          placeholder={t("UF")}
          func={(v?: string) => v?.toUpperCase().replace(/[^A-Z]/g, "") ?? ""}
          disabled={!!zipCodeAddress?.uf || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerStreet"}
          maxLength={200}
          label={t("Street")}
          xs={7}
          md={9}
          lg={6}
          disabled={!!zipCodeAddress?.logradouro || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          maxLength={6}
          property={"buyerNumber"}
          label={t("Number")}
          xs={3}
          lg={2}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerNeighborhood"}
          maxLength={100}
          label={t("Neighborhood")}
          xs={7}
          lg={6}
          disabled={!!zipCodeAddress?.bairro || props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"buyerComplement"}
          label={t("Complement")}
          xs={5}
          lg={4}
          disabled={props.viewMode}
        />
      </Form.Row>

      <Form.Row className="mt-1">
        <FormikControlCalendarAtom
          formik={formik}
          property="stockExitDate"
          label={t("CheckOutStockDate")}
          xs={7}
          lg={6}
          disabled={props.viewMode}
          translate={t}
        />
        <FormikControlAtom
          type={"money"}
          formik={formik}
          label={t("SaleValue")}
          property="saleValue"
          translate={t}
          placeholder="R$"
          func={(v?: string) => (!!v ? v : "")}
          xs={5}
          lg={4}
          disabled={props.viewMode}
        />
      </Form.Row>

      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"checkOutInvoiceKey"}
          label={t("invoiceKey")}
          type="only-numbers"
          allowLeadingZeros
          maxLength={MaskHelper.DANFE_NUMBER_LENGTH}
          placeholderChar={"\u2000"}
          lg={10}
          disabled={props.viewMode}
        />
      </Form.Row>
    </Form>
  );
};
