import {
  C4uVerticalStepperMolecule,
  DateHelper,
  IKbbVehicle,
  SourcePartnerEnum,
  SpinnerCenteredAtom,
  VehicleCategoriesEnum,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths, skipUpdateKey } from "../../../constants";
import { RenaveStatusHelper } from "../../../helper";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import { RenaveMenuStepsEnum, TypeDocumentEnum } from "../../../models";
import { HeaderTitleMolecule } from "../../molecules";
import {
  GenericLoadingWithText,
  StepContentWrapper,
} from "../shared/shared.page.style";

export const ManageStockBasePage: React.FC = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const params = useParams<{ id: string }>();
  const { getRenaveVehicleById } = useRenave();

  const {
    menuCurrentStepContext,
    setMenuCurrentStepContext,
    menuSelectedStepContext,
    setKbbVehicleContext,
    setRestrictionContext,
    vehicleEntryContext,
    setVehicleEntryContext,
    setVehicleDanfeContext,
    setVehicleCheckoutContext,
    setVehicleOutDanfeContext,
    userContext,
    renaveVehicleFetchedContext,
    setRenaveVehicleFetchedContext,
    setVehicleCategoryContext,
    setSourcePartnerContext
  } = useRenaveContext();

  const { showGenericErrorModal } = useSessionContext();

  const pushStepAptness = useCallback(
    () => push(`${paths.checkInAptness(params.id)}?${skipUpdateKey}=true`),
    [push, params.id]
  );
  const pushStepCheckInVehicle = useCallback(
    () => push(paths.checkInVehicle(params.id)),
    [push, params.id]
  );
  const pushStepCheckInDanfe = useCallback(
    () => push(paths.checkInDanfe(params.id)),
    [push, params.id]
  );
  const pushStepCheckedIn = useCallback(
    () => push(paths.vehicleCheckedIn(params.id)),
    [push, params.id]
  );
  const pushStepCheckOutVehicle = useCallback(
    () => push(paths.checkOutVehicle(params.id)),
    [push, params.id]
  );
  const pushStepCheckOutDanfe = useCallback(
    () => push(paths.checkOutDanfe(params.id)),
    [push, params.id]
  );
  const pushStepCheckedOut = useCallback(
    () => push(paths.vehicleCheckedOut(params.id)),
    [push, params.id]
  );
  const pushMyStockPage = useCallback(() => push(paths.myStock()), [push]);

  const steps = [
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.Aptness]),
      onClickStep:
        menuCurrentStepContext === RenaveMenuStepsEnum.Aptness
          ? undefined
          : pushStepAptness,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckInVehicle]),
      onClickStep: pushStepCheckInVehicle,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckInDanfe]),
      onClickStep: pushStepCheckInDanfe,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckedIn]),
      onClickStep: pushStepCheckedIn,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckOutVehicle]),
      onClickStep: pushStepCheckOutVehicle,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckOutDanfe]),
      onClickStep: pushStepCheckOutDanfe,
    },
    {
      name: t(RenaveMenuStepsEnum[RenaveMenuStepsEnum.CheckedOut]),
      onClickStep: pushStepCheckedOut,
    },
  ];

  const getReturnButtonAction = () => {
    switch (menuSelectedStepContext) {
      case RenaveMenuStepsEnum.CheckedOut:
        return pushStepCheckOutDanfe;

      case RenaveMenuStepsEnum.CheckOutDanfe:
        return pushStepCheckOutVehicle;

      case RenaveMenuStepsEnum.CheckOutVehicle:
        return pushStepCheckedIn;

      case RenaveMenuStepsEnum.CheckedIn:
        return pushStepCheckInDanfe;

      case RenaveMenuStepsEnum.CheckInDanfe:
        return pushStepCheckInVehicle;

      case RenaveMenuStepsEnum.CheckInVehicle:
        return pushStepAptness;

      case RenaveMenuStepsEnum.Aptness:
      default:
        return pushMyStockPage;
    }
  };

  useEffect(() => {
    if (params.id && renaveVehicleFetchedContext === undefined) {
      getRenaveVehicleById(params.id)
        .then((data) => {
          const menuStep = RenaveStatusHelper.getMenuStepFromStatus(data.step);
          if (menuStep) setMenuCurrentStepContext(menuStep);
          setRestrictionContext({
            vehicle: {
              plate: data.plate ?? "",
              renavam: data.renavam ?? "",
              numberCrv: data.numberCrv ?? "",
              typeCrv: data.typeCrv,
            },
          });

          if(data.kbbId || data.molicarId){
            setKbbVehicleContext({
              id: data.kbbId ?? 0,
              molicarID: data.molicarId ?? null,
              year: data.modelYear
            } as IKbbVehicle);
          }

          setVehicleCategoryContext(data.molicarId ? VehicleCategoriesEnum.Motorcycle : VehicleCategoriesEnum.Car)
          setSourcePartnerContext(data.molicarId ? SourcePartnerEnum.Molicar : SourcePartnerEnum.Kbb)

          setVehicleEntryContext({
            dealershipEmail: vehicleEntryContext?.dealershipEmail,
            kbbId: data.kbbId?.toString() ?? "",
            molicarID: data.molicarId ?? null,
            brand: data.brand ?? "",
            model: data.model ?? "",
            version: data.version ?? "",
            modelYear: data.modelYear ?? undefined,
            plate: data.plate ?? "",
            renavam: data.renavam ?? "",
            numberCrv: data.numberCrv ?? "",
            typeCrv: data.typeCrv,
            securityCodeCrv: data.securityCodeCrv ?? "",
            odometerMileage: data.odometerMileage ?? "",
            typeEntryEnum: data.typeEntryEnum ?? 0,
            typeSignature: data.typeSignature ?? undefined,
            measurementOdometerDate: data.measurementOdometerDate
              ? parseISO(data.measurementOdometerDate)
              : undefined,
            measurementOdometerHour: data.measurementOdometerHour ?? "",
            stockEntryDate: data.stockEntryDate
              ? parseISO(data.stockEntryDate)
              : undefined,
            manufactureYear: data.manufactureYear ?? undefined,
            stockEntryHour: data.stockEntryDate
              ? DateHelper.dateHourCustomFormat(data.stockEntryDate, "HH:mm")
              : "",
            typeDocumentCurrentOwner:
              data.purchaseData?.typeDocumentCurrentOwner ??
              TypeDocumentEnum.Cpf,
            documentCurrentOwner: data.purchaseData?.documentCurrentOwner ?? "",
            emailCurrentOwner: data.purchaseData?.emailCurrentOwner ?? "",
            datePurchase: data.purchaseData?.datePurchase
              ? parseISO(data.purchaseData?.datePurchase.toString())
              : undefined,
            valuePurchase: data.purchaseData?.valuePurchase,
          });
          setVehicleDanfeContext({
            id: data.id,
            invoiceKey: data.checkInInvoiceKey ?? "",
            name: data.userName,
            invoiceDate: data.checkInInvoiceKeyDate,
          });
          setVehicleCheckoutContext({
            id: data.id,
            city: data.buyerCity ?? "",
            zipCode: data.buyerZipCode ?? "",
            complement: data.buyerComplement ?? "",
            buyerDocument: data.buyerDocument ?? "",
            docType: data.buyerDocumentType,
            buyerEmail: data.buyerEmail ?? "",
            buyerName: data.buyerName ?? "",
            neighborhood: data.buyerNeighborhood ?? "",
            number: data.buyerNumber ?? "",
            street: data.buyerStreet ?? "",
            state: data.buyerUF ?? "",
            checkOutType: data.checkOutType,
            saleValue: data.saleValue,
            exitDate: data.stockExitDate
              ? DateHelper.dateFormat(data.stockExitDate)
              : "",
          });
          setVehicleOutDanfeContext({
            id: data.id,
            invoiceKey: data.checkOutInvoiceKey ?? "",
            name: data.userName,
            invoiceDate: data.checkOutInvoiceKeyDate,
          });
        })
        .then(() => setRenaveVehicleFetchedContext(true))
        .catch((e) => {
          console.log("Error fetching vehicle data", e);
          setRenaveVehicleFetchedContext(false);
          showGenericErrorModal(t("ErrorFetchingVehicleData"));
          pushMyStockPage();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, renaveVehicleFetchedContext]);

  useEffect(
    () => {
      if (params.id === undefined) setRenaveVehicleFetchedContext(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id]
  );

  useEffect(() => {
    if (
      renaveVehicleFetchedContext !== undefined &&
      userContext?.dealership?.email
    ) {
      setVehicleEntryContext({
        ...vehicleEntryContext,
        dealershipEmail: userContext.dealership.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.dealership?.email, renaveVehicleFetchedContext]);

  return (
    <>
      <HeaderTitleMolecule
        title={t("ManageStockPageTitle")}
        returnButtonActionOverride={getReturnButtonAction()}
        navigationButtonText={t("MyVehicles")}
        navigationButtonAction={pushMyStockPage}
      />
      <Row>
        <Col md={3} className="pl-5">
          <C4uVerticalStepperMolecule
            currentStep={menuCurrentStepContext}
            selectedStep={menuSelectedStepContext}
            textHeader={t("Steps")}
            steps={steps}
          />
        </Col>
        <Col md={9} lg={8}>
          {renaveVehicleFetchedContext === undefined ? (
            <GenericLoadingWithText>
              <SpinnerCenteredAtom />
              {t("LoadingVehicleData") + "..."}
            </GenericLoadingWithText>
          ) : (
            <StepContentWrapper>{props.children}</StepContentWrapper>
          )}
        </Col>
      </Row>
    </>
  );
};
