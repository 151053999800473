import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  IPostCustomerCheckOutZeroKmRequest,
  TypeDocumentEnum,
} from "../../../models";

export const ZeroKmPersonCheckoutFormValidation = (
  entryDanfeNumber: string
) => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);
  const msgEmail = t(MsgValidation.Email);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const validations = Yup.object<IPostCustomerCheckOutZeroKmRequest>({
    id: Yup.number().required(msgRequired),
    saleValue: Yup.number()
      .min(1, t("SaleValueMustBeGreaterThanZero"))
      .required(msgRequired),
    buyerDocumentType: Yup.number<TypeDocumentEnum>().required(msgRequired),
    buyerDocument: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(msgInvalid),
    buyerName: Yup.string().required(msgRequired).max(100, msgInvalid),
    buyerEmail: Yup.string().required(msgRequired).email(msgEmail),
    buyerZipCode: Yup.string().required(msgRequired),
    buyerStreet: Yup.string().required(msgRequired).max(200, msgInvalid),
    buyerNumber: Yup.string().required(msgRequired).max(6, msgInvalid),
    buyerComplement: Yup.string().max(100, msgInvalid),
    buyerNeighborhood: Yup.string().required(msgRequired).max(100, msgInvalid),
    buyerCity: Yup.string().required(msgRequired).max(100, msgInvalid),
    buyerUF: Yup.string().required(msgRequired).max(2, msgInvalid),
    stockExitDate: Yup.date().required(msgRequired),
    checkOutInvoiceKey: Yup.string()
      .required(msgRequired)
      .test(
        "invalid return danfe",
        t("Checkout Danfe can't be equal to entry Danfe"),
        (value): boolean => {
          if (!value) return true;
          if (value.length === 44 && value === entryDanfeNumber) return false;
          return true;
        }
      )
      .isValidByRegex(/\d{44}/, t("Invalid Danfe length")),
  });

  return validations;
};
