import { FormikControlAtom, MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenaveContext, useSessionContext } from "../../../hooks";
import {
  CheckoutTypeEnum,
  IPostDealershipCheckOutZeroKmRequest,
  PostDealershipCheckOutZeroKmRequest,
  RenaveMenuStepsZeroKmEnum,
} from "../../../models";
import { ZeroKmDealershipCheckoutFormValidation } from "./check-out-dealership-form-zero-km.molecule.validation";

interface IProps {
  setIsSubmitting: (v: boolean) => void;
  goClearForm: boolean;
  goSubmit: boolean;
  creditsToSubmit: () => Promise<boolean>;
  saveCheckout: (v: PostDealershipCheckOutZeroKmRequest) => Promise<void>;
  viewMode?: boolean;
}

export const CheckOutDealershipFormZeroKmMolecule: React.FC<IProps> = (
  props
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const {
    zeroKmVehicleCheckoutContext,
    setZeroKmVehicleCheckoutContext,
    setMenuCurrentStepZeroKmContext,
  } = useRenaveContext();
  const { handleFormikException, showGenericSuccessModal } =
    useSessionContext();

  const formik = useFormik<IPostDealershipCheckOutZeroKmRequest>({
    initialValues: {
      id: parseInt(id),
      buyerDocument: zeroKmVehicleCheckoutContext?.buyerDocument,
      saleValue: zeroKmVehicleCheckoutContext?.saleValue,
    } as IPostDealershipCheckOutZeroKmRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        const credits = await props.creditsToSubmit();
        if (credits) {
          await props.saveCheckout(
            new PostDealershipCheckOutZeroKmRequest(values)
          );

          showGenericSuccessModal(null, () => {
            history.push(paths.vehicleCheckedOutZeroKm(id));
            setMenuCurrentStepZeroKmContext(
              RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm
            );
          });
        }
      } catch (error: any) {
        handleFormikException(
          error?.message,
          setErrors,
          t("ErrorMsgOutOfStock")
        );
      } finally {
        setZeroKmVehicleCheckoutContext({
          id: values.id,
          buyerDocument: values.buyerDocument,
          saleValue: values.saleValue,
          checkOutZeroKmType: CheckoutTypeEnum.TransferAuthorization,
        });
      }
    },
    validationSchema: ZeroKmDealershipCheckoutFormValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setFieldValue("id", parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.goClearForm)
      formik.resetForm({
        values: {
          id: parseInt(id),
          buyerDocument: "",
          saleValue: undefined,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goClearForm]);

  useEffect(() => {
    props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          formik={formik}
          label={t("BusinessNumber")}
          property="buyerDocument"
          mask={MaskHelper.Cnpj}
          translate={t}
          md={6}
          disabled={props.viewMode}
        />

        <FormikControlAtom
          type="money"
          formik={formik}
          label={t("SaleValue")}
          property={"saleValue"}
          func={(v?: string) => (!!v ? v : "")}
          placeholder="R$"
          translate={t}
          md={4}
          disabled={props.viewMode}
        />
      </Form.Row>
    </Form>
  );
};
