import { ButtonTertiary } from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../../../../hooks";
import { ConflictRequestError } from "../../../../models";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";
import { FormikControlCalendarAtom } from "c4u-web-components";
import {
  GenerateBankSlipModalFormValidation,
  maxDueDateLimit,
  minDueDateLimit,
} from "./generate-bank-slip-modal.molecule.validation";

import {
  ActionButtonsGroup,
  ModalBodyFormRow,
} from "./generate-bank-slip-modal.molecule.style";

interface IProps {
  isModalVisible: boolean;
  setIsModalVisible: (v: boolean) => void;
  onSubmit: (values: any, errors: any) => Promise<void>;
}

export type BankSlipAcquisitionForm = {
  dueDate: Date | undefined;
};

export const GenerateBankSlipModalMolecule: React.FC<IProps> = ({
  isModalVisible,
  setIsModalVisible,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const formik = useFormik<BankSlipAcquisitionForm>({
    initialValues: { dueDate: undefined } as BankSlipAcquisitionForm,
    onSubmit: async (values, { setErrors }) => {
      try {
        await onSubmit(values, { setErrors });
      } catch (e: any) {
        if (e instanceof ConflictRequestError) {
          handleFormikException(e, setErrors);
        } else {
          showGenericErrorModal(t("GenericErrorMsg"), onHide, t("Error"));
        }
      } finally {
        onHide();
      }
    },
    validationSchema: GenerateBankSlipModalFormValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const onHide = () => {
    setIsModalVisible(false);
    formik.resetForm();
  };

  return (
    <FeedbackModal
      show={isModalVisible}
      message={t("ConfirmGenerateBankSlipMessage")}
      titleHeader={t("GenerateBankSlip")}
      onHide={onHide}
      typeIcon="none"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalBodyFormRow>
          <FormikControlCalendarAtom
            label={t("dueDate")}
            property="dueDate"
            formik={formik}
            xs={6}
            minDate={minDueDateLimit}
            maxDate={maxDueDateLimit}
            translate={t}
          />
        </ModalBodyFormRow>
        <Form.Row>
          <ActionButtonsGroup>
            <ButtonTertiary
              sizex={"md"}
              type="submit"
              loading={formik.isSubmitting}
            >
              {t("Ok")}
            </ButtonTertiary>
          </ActionButtonsGroup>
        </Form.Row>
      </Form>
    </FeedbackModal>
  );
};
