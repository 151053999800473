import {
  Combo,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
  VehicleSearchOptions,
  VehicleSearchOptionsProps,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useKbb, useRenaveContext } from "../../../hooks";

interface IProps {
  vehicleKbbId?: string;
  vehicleMolicarId?: string;
  molicarID?: string;
  className?: string;
  isZeroKm?: boolean;
  sourcePartner: SourcePartnerEnum;
  setSourcePartner: (sourcePartner: SourcePartnerEnum) => void;
  setHasSelectedCategory: (value: boolean) => void;
}

export const StaticVehicleOptions: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  const {
    kbbVehicleContext,
    setKbbVehicleContext,
    vehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useRenaveContext();

  const { GetVehicle } = useKbb();

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<Combo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<Combo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();
  const [yearsCombo, setYearsCombo] = useState<Combo[]>();

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<Combo>();
  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();
  const [vehicleModelSelected, setVehicleModelSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();

  const [haveYearsSelected, setHaveYearsSelected] = useState(false);

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );
  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,
    [vehicleCategoryContext]
  );

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      if (!kbbVehicleContext?.versionID) {
        const vehicle = await GetVehicle(id, molicarID, year);
        setKbbVehicleContext(vehicle);
      }
    },
    [GetVehicle, kbbVehicleContext?.versionID, setKbbVehicleContext]
  );

  useEffect(() => {
    if (isCarCategory) {
      setSourcePartnerContext(SourcePartnerEnum.Kbb);
    } else {
      setSourcePartnerContext(SourcePartnerEnum.Molicar);
    }
  }, [isCarCategory, setSourcePartnerContext, sourcePartnerContext]);

  useEffect(() => {
    if (kbbVehicleContext !== undefined && !vehicleVersionCombo) {
      if (isSourceKbb) {
        getVehicleAsync(
          Number(kbbVehicleContext.id),
          null,
          kbbVehicleContext.year
        );
      } else {
        getVehicleAsync(0, kbbVehicleContext.molicarID, kbbVehicleContext.year);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleVersionSelected, vehicleYearSelected?.value]);

  useEffect(() => {
    if (kbbVehicleContext && vehicleBrandCombo) {
      setVehicleBrandSelected(
        vehicleBrandCombo?.find((f) => f.value === kbbVehicleContext.brandID) ||
          new Combo({ title: "", value: "" })
      );
    }
  }, [kbbVehicleContext, vehicleBrandCombo]);

  useEffect(() => {
    if (kbbVehicleContext && yearsCombo) {
      setVehicleYearSelected(
        yearsCombo?.find((f) => f.value === kbbVehicleContext.year) ||
          new Combo({ title: "", value: "" })
      );
    }
  }, [kbbVehicleContext, yearsCombo]);

  useEffect(() => {
    if (kbbVehicleContext && vehicleModelCombo) {
      setVehicleModelSelected(
        vehicleModelCombo?.find((f) => f.value === kbbVehicleContext.modelID) ||
          new Combo({ title: "", value: "" })
      );
    }
  }, [kbbVehicleContext, vehicleModelCombo]);

  useEffect(() => {
    if (kbbVehicleContext && vehicleVersionCombo) {
      setVehicleVersionSelected(
        isSourceKbb
          ? vehicleVersionCombo?.find((f) => f.value === kbbVehicleContext.id)
          : vehicleVersionCombo?.find(
              (f) => f.value === kbbVehicleContext.molicarID
            )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext, vehicleVersionCombo]);

  useEffect(() => {
    if (kbbVehicleContext?.year === 0) {
      setHaveYearsSelected(false);
    } else {
      setHaveYearsSelected(true);
    }
  }, [kbbVehicleContext?.year, vehicleYearSelected]);

  useEffect(() => {
    vehicleBrandSelected
      ? props.setHasSelectedCategory(true)
      : props.setHasSelectedCategory(false);
  }, [props, vehicleBrandSelected]);

  useEffect(() => {
    if (kbbVehicleContext?.versionID) {
      setVehicleBrandCombo([
        new Combo({
          title: kbbVehicleContext.brandName,
          value: kbbVehicleContext.brandID,
        }),
      ]);

      setVehicleModelCombo([
        new Combo({
          title: kbbVehicleContext.modelName,
          value: kbbVehicleContext.modelID,
        }),
      ]);

      setVehicleVersionCombo([
        new Combo({
          title: kbbVehicleContext.versionName,
          value: isSourceKbb
            ? kbbVehicleContext.id
            : kbbVehicleContext.molicarID,
        }),
      ]);

      setYearsCombo([
        new Combo({
          title:
            kbbVehicleContext.year === 2023
              ? `${kbbVehicleContext.year}*`
              : kbbVehicleContext.year,
          value: kbbVehicleContext.year,
        }),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext]);

  const propsVehicleSearchOptions = useMemo((): VehicleSearchOptionsProps => {
    return {
      Dropdowns: {
        showAll: true,
        haveYearsSelected: haveYearsSelected,
        Brand: {
          placeholder: t("Select") + "...",
          Options: vehicleBrandCombo,
          Value: vehicleBrandSelected,
          disabled: true,
        },
        Model: {
          placeholder: t("Select") + "...",
          Options: vehicleModelCombo,
          Value: vehicleModelSelected,
          disabled: true,
        },
        Version: {
          placeholder: t("Select") + "...",
          Options: vehicleVersionCombo,
          Value: vehicleVersionSelected,
          disabled: true,
        },
        Year: {
          placeholder: t("Select") + "...",
          Options: yearsCombo,
          Value: vehicleYearSelected,
          disabled: haveYearsSelected ? false : true,
          onChange: setVehicleYearSelected,
        },
      },
      Texts: {
        Brand: t("Brand"),
        ClearFilter: t("Clean Filters"),
        Loading: t("Loading"),
        Model: t("Model"),
        NoMatchesFound: t("NoMatchesFound"),
        Others: t("Others"),
        TitleYears: t("ModelYear"),
        Version: t("Version"),
      },
    };
    // eslint-disable-next-line
  }, [
    vehicleBrandCombo,
    yearsCombo,
    vehicleModelCombo,
    vehicleVersionCombo,
    vehicleBrandSelected,
    vehicleYearSelected,
    vehicleModelSelected,
    vehicleVersionSelected,
    t,
    haveYearsSelected,
  ]);

  return (
    <Row>
      <Col>
        <VehicleSearchOptions {...propsVehicleSearchOptions} />
      </Col>
    </Row>
  );
};
