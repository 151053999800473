import { TypesHelper } from "c4u-web-components";
import { CheckoutTypeEnum } from "../..";

export interface ISaveOutOfStockPersonRequest {
  id: string;
  docType: string;
  buyerDocument: string;
  buyerName: string;
  buyerEmail: string;
  saleValue: number;
  zipCode: string;
  street: string;
  complement?: string;
  number: string;
  state: string;
  city: string;
  neighborhood: string;
  buyerCityCode?: number;
  isEdit?: boolean;
  exitDate: string;
}

export class SaveOutOfStockPersonRequest {
  id: number | null;
  buyerDocumentType: number | null;
  buyerDocument: string | null;
  buyerName: string | null;
  buyerEmail: string | null;
  saleValue: number | null;
  buyerZipCode: string | null;
  buyerStreet: string | null;
  buyerNumber: string | null;
  buyerUF: string | null;
  buyerCity: string | null;
  buyerNeighborhood: string | null;
  buyerComplement: string | null;
  buyerCityCode: string | null;
  saleCityCode: number | null;
  isEdit: boolean | null;
  checkOutType: CheckoutTypeEnum | null;
  stockExitDate: Date | null;

  constructor({
    id,
    docType,
    buyerDocument,
    buyerName,
    buyerEmail,
    saleValue,
    zipCode,
    street,
    complement,
    number,
    state,
    city,
    neighborhood,
    buyerCityCode,
    exitDate,
  }: ISaveOutOfStockPersonRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.buyerDocumentType = TypesHelper.toNumberNull(docType);
    this.buyerDocument = TypesHelper.toStringOnlyNumbersNull(buyerDocument);
    this.buyerName = TypesHelper.toStringNull(buyerName);
    this.buyerEmail = TypesHelper.toStringNull(buyerEmail);
    this.saleValue = TypesHelper.toNumberNull(saleValue);
    this.buyerZipCode = TypesHelper.toStringOnlyNumbersNull(zipCode);
    this.buyerStreet = TypesHelper.toStringNull(street);
    this.buyerNumber = TypesHelper.toStringNull(number);
    this.buyerUF = TypesHelper.toStringNull(state);
    this.buyerCity = TypesHelper.toStringNull(city);
    this.buyerNeighborhood = TypesHelper.toStringNull(neighborhood);
    this.buyerComplement = TypesHelper.toStringNull(complement);
    this.buyerCityCode = TypesHelper.toStringNull(buyerCityCode);
    this.saleCityCode = TypesHelper.toNumberNull(buyerCityCode);
    this.isEdit = false;
    this.checkOutType = TypesHelper.toNumberNull(CheckoutTypeEnum.Exit);
    this.stockExitDate = TypesHelper.toDateNull(exitDate);
  }
}
