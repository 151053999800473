import {
  C4uVerticalStepperMolecule,
  DateHelper,
  IKbbVehicle,
  SourcePartnerEnum,
  SpinnerCenteredAtom,
  VehicleCategoriesEnum,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { RenaveStatusHelper } from "../../../helper";
import { useRenave, useRenaveContext } from "../../../hooks";
import { RenaveMenuStepsZeroKmEnum } from "../../../models/enums/renave-steps.enum";
import { HeaderTitleMolecule } from "../../molecules";
import {
  GenericLoadingWithText,
  StepContentWrapper,
} from "../shared/shared.page.style";

export const MaganeStockZeroKmBasePage: React.FC = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const params = useParams<{ id: string }>();

  const { getRenaveVehicleById } = useRenave();

  const {
    menuCurrentStepZeroKmContext,
    setMenuCurrentStepZeroKmContext,
    menuSelectedStepZeroKmContext,
    renaveVehicleFetchedContext,
    setRenaveVehicleFetchedContext,
    setZeroKmVehicleEntryContext,
    setZeroKmVehicleCheckoutContext,
    setKbbVehicleContext,
    kbbVehicleContext,
    zeroKmVehicleEntryContext,
    setVehicleCategoryContext,
    setSourcePartnerContext
  } = useRenaveContext();

  const pushStep1 = useCallback(
    () => push(paths.checkInZeroKm(params.id)),
    [push, params.id]
  );
  const pushStep2 = useCallback(
    () => push(paths.vehicleCheckedInZeroKm(params.id)),
    [push, params.id]
  );
  const pushStep3 = useCallback(
    () => push(paths.checkOutZeroKm(params.id)),
    [push, params.id]
  );
  const pushStep4 = useCallback(
    () => push(paths.vehicleCheckedOutZeroKm(params.id)),
    [push, params.id]
  );
  const pushMyStockPage = useCallback(() => push(paths.myStock()), [push]);

  const steps = [
    {
      name: t("CheckInVehicle"),
      onClickStep: pushStep1,
    },
    {
      name: t("CheckedIn"),
      onClickStep: pushStep2,
    },
    {
      name: t("CheckOutVehicle"),
      onClickStep: pushStep3,
    },
    {
      name: t("CheckedOut"),
      onClickStep: pushStep4,
    },
  ];

  const getReturnButtonAction = () => {
    switch (menuSelectedStepZeroKmContext) {
      case RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm:
        return pushStep4;

      case RenaveMenuStepsZeroKmEnum.CheckOutVehicleZeroKm:
        return pushStep3;

      case RenaveMenuStepsZeroKmEnum.CheckedInZeroKm:
        return pushStep2;

      case RenaveMenuStepsZeroKmEnum.CheckInVehicleZeroKm:
      default:
        return pushMyStockPage;
    }
  };

  useEffect(
    () => {
      if (params.id === undefined) setRenaveVehicleFetchedContext(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id]
  );

  useEffect(() => {
    if (params.id && renaveVehicleFetchedContext === undefined) {
      getRenaveVehicleById(params.id)
        .then((data) => {
          const menuStep = RenaveStatusHelper.getZeroKmMenuStepFromStatus(
            data.step
          );
          if (menuStep) setMenuCurrentStepZeroKmContext(menuStep);

          if(data.kbbId || data.molicarId){
            setKbbVehicleContext({
              id: data.kbbId ?? 0,
              molicarID: data.molicarId ?? null,
              year: data.modelYear
            } as IKbbVehicle);
          }

          setVehicleCategoryContext(data.molicarId ? VehicleCategoriesEnum.Motorcycle : VehicleCategoriesEnum.Car)
          setSourcePartnerContext(data.molicarId ? SourcePartnerEnum.Molicar : SourcePartnerEnum.Kbb)

          setZeroKmVehicleEntryContext({
            id: data.id,
            kbbId: data.kbbId ?? 0,
            molicarID: data.molicarId ?? null,
            vin: data.vin ?? "",
            manufactureYear: data.manufactureYear ?? undefined,
            valuePurchase: data.purchaseData?.valuePurchase ?? "",
            entryType: t("SolicitationEntryZeroKm"),
            stockEntryDate: data.stockEntryDate
              ? parseISO(data.stockEntryDate)
              : undefined,
            odometerMileage: data.odometerMileage,
            measurementOdometerDate: data.measurementOdometerDate
              ? parseISO(data.measurementOdometerDate)
              : undefined,
            measurementOdometerHour: data.measurementOdometerDate
              ? DateHelper.formatTime(
                  parseISO(data.measurementOdometerDate)
                ).replace("h", ":")
              : "",
            invoiceKey: data.checkInInvoiceKey ?? "",
            brand: data.brand,
            version: data.version,
            model: data.model,
            modelYear: data.modelYear,
          });
          setZeroKmVehicleCheckoutContext({
            id: data.id,
            saleValue: data.saleValue,
            buyerDocumentType: data.buyerDocumentType,
            buyerDocument: data.buyerDocument,
            buyerName: data.buyerName,
            buyerEmail: data.buyerEmail,
            buyerZipCode: data.buyerZipCode,
            buyerStreet: data.buyerStreet,
            buyerNumber: data.buyerNumber,
            buyerComplement: data.buyerComplement,
            buyerNeighborhood: data.buyerNeighborhood,
            buyerCityCode: data.buyerCityCode,
            buyerCity: data.buyerCity,
            buyerUF: data.buyerUF,
            stockExitDate: !!data.stockExitDate
              ? parseISO(data.stockExitDate)
              : undefined,
            checkOutInvoiceKey: data.checkInInvoiceKey,
            checkOutInvoiceKeyDate: !!data.checkInInvoiceKeyDate
              ? parseISO(data.checkInInvoiceKeyDate)
              : undefined,
            checkOutZeroKmType: data.checkOutZeroKmType ?? undefined,
          });
        })
        .then(() => setRenaveVehicleFetchedContext(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, renaveVehicleFetchedContext]);

  // Talvez precise trazer o efeito da página de check-in para esta
  useEffect(() => {
    if (
      zeroKmVehicleEntryContext &&
      !zeroKmVehicleEntryContext.brand &&
      kbbVehicleContext?.brandName
    )
      setZeroKmVehicleEntryContext({
        ...zeroKmVehicleEntryContext,
        brand: kbbVehicleContext?.brandName,
        version: kbbVehicleContext?.versionName,
        model: kbbVehicleContext?.modelName,
        modelYear: kbbVehicleContext?.year,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext]);

  return (
    <>
      <HeaderTitleMolecule
        title={t("ManageStockZeroKmPageTitle")}
        returnButtonActionOverride={getReturnButtonAction()}
        navigationButtonText={t("MyVehicles")}
        navigationButtonAction={pushMyStockPage}
      />
      <Row>
        <Col md={3} className="pl-5">
          <C4uVerticalStepperMolecule
            currentStep={menuCurrentStepZeroKmContext}
            selectedStep={menuSelectedStepZeroKmContext}
            textHeader={t("Steps")}
            steps={steps}
          />
        </Col>
        <Col md={9} lg={8}>
          {renaveVehicleFetchedContext === undefined ? (
            <GenericLoadingWithText>
              <SpinnerCenteredAtom />
              {t("LoadingVehicleData") + "..."}
            </GenericLoadingWithText>
          ) : (
            <StepContentWrapper>{props.children}</StepContentWrapper>
          )}
        </Col>
      </Row>
    </>
  );
};
