import {
  CoxIcon,
  MaskHelper,
  MsgValidation,
  YupHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ICreditAcquisitionForm, PaymentMethodEnum } from "../../../models";
import { FormikControlRenaveAtom } from "../../atoms";
import {
  BoxTheBankSplip,
  BoxTheButtonPayment,
  BoxTheCreditCard,
  ButtonBankSplipGeneration,
  CreditAcquisitionStyledForm,
  CreditPurchaseOptionPayment,
} from "./credit-acquisition-form.molecule.style";

interface IProps {
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  setIsSubmitting?: (v: boolean) => void;
  packId: number;
  selectedPaymentMethod: PaymentMethodEnum | undefined;
  setSelectedPaymentMethod: (method: PaymentMethodEnum | undefined) => void;
  openBankSlipModalHandle: (value: boolean) => void;
}

type CreditCardAcquisitionForm = Omit<ICreditAcquisitionForm, "dueDate">

export const CreditAcquisitionFormMolecule: React.FC<IProps> = ({ goSubmit, onSubmit, setIsSubmitting, packId, selectedPaymentMethod, setSelectedPaymentMethod, openBankSlipModalHandle }) => {
  const { t } = useTranslation();

  const initialValues: CreditCardAcquisitionForm = {
    expiration: "",
    cardholder: "",
    cardNumber: "",
    cvv: ""
  };

  const msgRequired = t(MsgValidation.RequiredField);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCreditCardValid",
    YupHelper.isCreditCardValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const validations = Yup.object<CreditCardAcquisitionForm>({
    cardholder: Yup.string()
      .required(msgRequired)
      .max(100, t(MsgValidation.Cvv)),
    cardNumber: Yup.string()
      .required(msgRequired)
      .isCreditCardValid(t(MsgValidation.CreditCard)),
    expiration: Yup.string()
      .required(msgRequired)
      .isValidByRegex(/(0[1-9]|1[0-2])\/\d\d/, t(MsgValidation.Date)),
    cvv: Yup.string()
      .required(msgRequired)
      .isValidByRegex(/\d{3}/, t(MsgValidation.Cvv)),
  });

  const formik = useFormik<CreditCardAcquisitionForm>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (onSubmit) await onSubmit(values, { setErrors });
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: validations,
  });

  useEffect(() => {
    if (goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goSubmit]);

  useEffect(() => {
    if (setIsSubmitting) setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  useEffect(() => {
    formik.resetForm();
    setSelectedPaymentMethod(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packId]);

  return (
    <CreditAcquisitionStyledForm onSubmit={formik.handleSubmit}>
      <BoxTheButtonPayment>
        <CreditPurchaseOptionPayment
          selectButton={selectedPaymentMethod === PaymentMethodEnum.creditCard}
          onClick={() => {
            setSelectedPaymentMethod(PaymentMethodEnum.creditCard);
          }}
          type="button"
        >
          <CoxIcon
            fillColor={
              selectedPaymentMethod === PaymentMethodEnum.creditCard
                ? "#FFFFFF"
                : "#3a5693"
            }
            name="dolar-circle-v2"
            width={20}
          />
          Cartão de Crédito
        </CreditPurchaseOptionPayment>

        <CreditPurchaseOptionPayment
          selectButton={selectedPaymentMethod === PaymentMethodEnum.bankSlip}
          onClick={() => {
            setSelectedPaymentMethod(PaymentMethodEnum.bankSlip);
          }}
          type="button"
        >
          {" "}
          <CoxIcon
            fillColor={
              selectedPaymentMethod === PaymentMethodEnum.bankSlip
                ? "#FFFFFF"
                : "#3a5693"
            }
            name="bar-code"
            width={20}
          />
          Boleto Bancário
        </CreditPurchaseOptionPayment>
      </BoxTheButtonPayment>

      {selectedPaymentMethod === PaymentMethodEnum.creditCard && (
        <>
          <BoxTheCreditCard>
            <span>{t("EnterYourPaymentDetails")}</span>
          </BoxTheCreditCard>

          <Form.Row className="d-flex align-items-baseline">
            <FormikControlRenaveAtom
              formik={formik}
              property={"cardholder"}
              label={t("CreditCardName")}
              md={4}
              maxLength={100}
            />
          </Form.Row>
          <Form.Row className="d-flex align-items-baseline">
            <FormikControlRenaveAtom
              formik={formik}
              property={"cardNumber"}
              label={t("CreditCardNumber")}
              md={3}
              mask={MaskHelper.CreditCard}
              placeholderChar={"\u2000"}
            />

            <FormikControlRenaveAtom
              formik={formik}
              property={"expiration"}
              label={t("CreditCardExpirationDate")}
              md={2}
              mask={MaskHelper.CreditCardValidity}
              placeholder="MM/AA"
            />

            <FormikControlRenaveAtom
              formik={formik}
              property={"cvv"}
              label={t("CreditCardCVV")}
              md={2}
              mask={MaskHelper.CreditCardCVV}
            />
          </Form.Row>
        </>
      )}

      {selectedPaymentMethod === PaymentMethodEnum.bankSlip && (
        <>
          <BoxTheBankSplip>
            <span>{t("ClickToGenerateTheBankSlipForPayment")}</span>
            <div>
              <ButtonBankSplipGeneration sizex="md" onClick={() => openBankSlipModalHandle(true)}>
                <CoxIcon fillColor={"#FFFFFF"} name="bar-code" width={20} />
                {t("GenerateBankSlip")}
              </ButtonBankSplipGeneration>
            </div>
          </BoxTheBankSplip>
        </>
      )}
    </CreditAcquisitionStyledForm>
  );
};
