import React from "react";
import { useTranslation } from "react-i18next";
import { IGetSummaryResponse } from "../../../models";

import {
  StatusSummaryWrapper,
  StatusSummaryItemWrapper,
  BlueCircle,
  GoldCircle,
  GreenCircle,
  RedCircle,
  StatusSummaryText,
} from "./status-summary.molecule.style";

interface IProps {
  summaryData?: IGetSummaryResponse;
}

export const StatusSummaryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { summaryData } = props;

  return (
    <StatusSummaryWrapper>
      <StatusSummaryItemWrapper>
        <StatusSummaryText>{t("AllStatus")}</StatusSummaryText>
        <BlueCircle>{summaryData?.all ?? "-"}</BlueCircle>
      </StatusSummaryItemWrapper>
      <StatusSummaryItemWrapper>
        <StatusSummaryText>{t("PendingApproval")}</StatusSummaryText>
        <GoldCircle>{summaryData?.approvalPending ?? "-"}</GoldCircle>
      </StatusSummaryItemWrapper>
      <StatusSummaryItemWrapper>
        <StatusSummaryText>{t("PendingCheckin")}</StatusSummaryText>
        <GreenCircle>{summaryData?.entryPending ?? "-"}</GreenCircle>
      </StatusSummaryItemWrapper>
      <StatusSummaryItemWrapper>
        {/*Quando o BackEnd foi feito o nome desse bullet no header {t("PendingCheckin0km")}  */}
        <StatusSummaryText>{t("PendingCheckout")}</StatusSummaryText>
        <RedCircle>{summaryData?.outPending ?? "-"}</RedCircle>
      </StatusSummaryItemWrapper>
    </StatusSummaryWrapper>
  );
};
